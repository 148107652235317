<template>

  <div>
    <!-- eslint-disable -->
    <b-overlay
      variant="white"
      :show="showLoading"
      spinner-variant="primary"
      blur="0"
      opacity=".75"
      rounded="sm"
    >
      </b-overlay>
      <!-- Filters -->
      <company-list-filters
        :role-options="roleOptions"
      />
      <!-- Table Container Card -->
      <b-card
        no-body
        class="mb-0"
      >
        <b-table
          class="position-relative"
          :hover="true"
          :items="records"
          responsive
          :fields="fields"
          show-empty
          empty-text="No existen"
          @sort-changed="sortChanged"
        >
          <!-- Column: USER -->
          <template #cell(business_name)="data">
            <b-media vertical-align="center">
              <b-link
                :to="{ name: 'companys-edit', params: { id: data.item.id } }"
                class="font-weight-bold d-block text-nowrap"
              >
                {{ data.item.business_name }}
              </b-link>
            </b-media>
          </template>
          <!-- Column: USER -->
          <template #cell(doi)="data">
            <b-media vertical-align="center">
              <b-link
                :to="{ name: 'companys-edit', params: { id: data.item.id } }"
                class="font-weight-bold d-block text-nowrap text-secondary"
              >
                {{ data.item.doi }}
              </b-link>
            </b-media>
          </template>
          <!-- Column: USER -->
          <template #cell(address)="data">
            <b-media vertical-align="center">
              <b-link
                :to="{ name: 'companys-edit', params: { id: data.item.id } }"
                class="font-weight-bold d-block text-nowrap text-secondary"
              >
                {{ data.item.address }}
              </b-link>
            </b-media>
          </template>
          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <b-button
              size="sm"
              class=""
              @click="editAction(data)"
              variant="flat-success"
            >
              <feather-icon
                icon="PenToolIcon"
                
              />
            </b-button>
            <b-button
              size="sm"
              class=""
              variant="flat-danger"
              @click="deleteAction(data)"
            >
              <feather-icon
                
                variant=""
                icon="Trash2Icon"
              />
            </b-button>
          </template>

        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col sm="3">
              <b-form-group
                label-cols="4"
                label-cols-md="4"
                label-size="md"
                label="Entradas"
                label-for="input-md"
              >
                <b-form-select
                  v-model="showEntrie"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  :options="entries"
                  @change="changeSizePage"
                />
              </b-form-group>
            </b-col>
            <b-col
              sm="3"
              class="mt-75"
              style="font-size:12px"
            >
              <span> {{ totalElements }} Registros en total</span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalElements"
                :per-page="showEntrie"
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
                @change="cambioPagina"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text >
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
/* eslint-disable */
import {
  BCard, BRow, BCol, BButton, BTable, BMedia, BLink,
  BPagination, BFormSelect, BFormGroup, BOverlay,
} from 'bootstrap-vue'
// import vSelect from 'vue-select'
import CompanyService from '@/services/CompanyService'
import CompanyListFilters from './CompanyListFilters.vue'

export default {
  methods: {
    editUser(id) {
      console.log(id)
    },
  },
  components: {
    CompanyListFilters,
    BFormSelect,
    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BMedia,
    BLink,
    BFormGroup,
    BPagination,
    BOverlay,
  },
  data() {
    return {
      records: [],
      fields: [
        { key: 'business_name',label:'Razon Social', sortable: true },
        { key: 'doi',label:'RUC', sortable: true },
        { key: 'address',label:'Dirección', sortable: true },
        { key: 'actions',label:'Acciones' },
      ],
      roleOptions: [
        { label: 'Todos', value: null },
        { label: 'Admin', value: 'admin' },
        { label: 'Contratista', value: 'contratista' },
        { label: 'Regulador', value: 'regulador' },
      ],
      arrayFilters: [],
      roleFilter: null,
      nameFilter: null,
      lastNameFilter: null,
      emailFilter: null,
      currentPage: 1,
      entries: [10, 20, 50, 100],
      showEntrie: 10,
      totalElements: 0,
      is_filter: false,
      id: 0,
      sort: 'id',
      order: 'desc',
      showLoading: false,
      arrayFilters: [],
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    cambioPagina(e) {
      this.currentPage = e
      this.getData()
    },
    editAction(data) {
      console.log('data', data)
      this.$router.push('/company/edit/'+data.item.id)
    },
    async deleteAction(data) {
      this.$swal({
        title: '¿Desea eliminar esta empresa?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si, eliminalo',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.currentPage = 1
          this.$swal({
            icon: 'success',
            title: 'Eliminado!',
            text: 'La empresa ha sido eliminada.',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
          this.deleteData(data.item.id)
        }
      })
    },
    async deleteData(id){
      const resp = await CompanyService.deleteCompany(id,this.$store)
      this.getData()
      console.log('respDelete',resp)
    },
    changeSizePage() {
      this.getData()
    },
    sortChanged(data) {
      this.sort = data.sortBy
      if (data.sortDesc) {
        this.order = 'desc'
      } else this.order = 'asc'
      this.getData()
    },
    async getData() {
      this.showLoading = true
      const url = `?limit=${this.showEntrie}&page=${this.currentPage}&order=${this.order}&sort=${this.sort}&filter=`+JSON.stringify(this.arrayFilters)
      const resp = await CompanyService.getCompanys(url, this.$store)
      if(resp){
        this.records = resp.rows
        this.totalElements=resp.responseFilter.total_rows
      }
      this.showLoading = false
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
